import React, { useEffect, useRef, useState } from 'react';
import '../App.css';
import Footer from './Footer';
import Modal from './Modal';
import { Link } from 'react-router-dom';
import VisionToLife from './VisionToLife';
import VirtualTour from './VirtualTour';
import Renders from './Renders';
import YouOwnApp from './YouOwnApp';
import Animation from './Animation';
import Jser from './Jser';
import Tooltip from './Tooltip';
import ImageCarousel from "./ImageCarousel";

const renderImages = [
  'render1.jpeg', 'render2.jpeg', 'render3.jpeg', 'render4.jpeg', 'render5.jpeg', 'render6.jpeg',
  'render7.jpeg', 'render8.jpeg', 'render9.jpeg', 'render10.jpeg', 'render12.jpeg',
  'render14.jpeg', 'render15.jpeg', 'render16.jpeg', 'render17.jpeg', 'render18.jpeg',
  'render19.png', 'render20.png', 'render21.png', 'render22.png', 'render23.png', 'render24.png',
  'render25.png', 'render26.png', 'render27.png', 'render28.png',
  'render31.png', 'render33.jpeg', 'render34.jpeg', 'render35.jpeg', 'render36.png',
  'render37.png', 'render38.png', 'render39.png', 'render40.jpeg', 'render41.jpeg', 'render42.jpeg',
  'render43.jpeg', 'render44.jpeg', 'render45.jpeg', 'render46.jpeg'
]

const landingCarousel = [
  { type: 'image', image: '/images/cinematics.png', header: 'High-Impact Animations', description: 'Engage clients with cinematic walkthroughs that highlight every detail of your project.', title: 'High-Impact Animations', btnName: 'ENQUIRE NOW', backgroundColor: '#393433', link: '#cinematic' },
  { type: 'image', image: '/images/360-tour.png', header: 'Interactive Virtual Tours', description: 'Allow clients to explore spaces freely with immersive 360° tours.', title: 'Interactive Virtual Tours', btnName: 'DISCOVER MORE', backgroundColor: '#97897b', link: '#360-tour' },
  { type: 'image', image: '/images/render.png', header: 'Photorealistic Renderings', description: 'Inspire confidence with high-definition, lifelike images that showcase every corner of your property.', title: 'Photorealistic Renderings', btnName: 'DISCOVER MORE', backgroundColor: '#efa32a', link: '#renders' },
  { type: 'image', image: '/images/custom-app.png', header: 'Custom Interactive Apps', description: 'Deepen client engagement with tailored digital experiences designed to highlight your project’s unique features.', title: 'Custom Interactive Apps', btnName: 'DISCOVER MORE', backgroundColor: '#b99167', link: '#custom-apps' },
  { type: 'video', image: '/images/jamie.png', header: '3D Architectural Visuals that Sell Off-Plan properties Faster', description: 'Best in class, 3D animation, 360 Virtual Tours  and Renders that captivate buyers and accelerate sales.', title: 'Jamie Durie Presents', btnName: 'DISCOVER MORE', backgroundColor: '#c7eced', link: '#vision-to-life' },
]

const Home = ({ t, currentLanguage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isModalJenOpen, setIsModalJenOpen] = useState(false);
  const [discoverMore, setDiscoverMore] = useState({ jser: false, vision: false, animation: false, virtualTour: false, renders: false, app: false })
  const [isLoading, setIsLoading] = useState(true);
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const [isRenderOpen, setIsRenderOpen] = useState(false)
  const handleIframeLoad = () => {
    setIsIframeLoading(false);
  };

  const handleLoadedData = () => {
    setIsLoading(false); // Hide loader once video is fully loaded
  };

  useEffect(() => {
    if (!isTourOpen) {
      setIsIframeLoading(true)
    }
  }, [isTourOpen])
  useEffect(() => {
    if (isModalOpen) {
      document.getElementById('backgroundVideo').pause()
    }
  }, [isModalOpen])

  useEffect(() => {
    const sections = document.querySelectorAll('.fade-in-section');
    const banners = document.querySelectorAll('.animate-slide-in-left');

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
            observer.unobserve(entry.target); // Optional: Stop observing after it has shown
          }
        });
      },
      { threshold: 0.15 }
    );
    banners.forEach(banner => observer.observe(banner));
    sections.forEach(section => observer.observe(section));

    // Cleanup on unmount
    return () => {
      sections.forEach(section => observer.unobserve(section));
      banners.forEach(banner => observer.unobserve(banner));
    };
  }, []);


  const sectionRef = {
    next: useRef(null),
    vision: useRef(null),
    animation: useRef(null),
    virtualTour: useRef(null),
    render: useRef(null),
    ownApp: useRef(null),
  }
  const scrollToNextSection = (nextSection) => {
    sectionRef[nextSection].current.scrollIntoView({ behavior: 'smooth' });
  };

  const showDiscoverMore = (item) => {
    setDiscoverMore({ ...discoverMore, [item]: true })
  }

  const widthClass = () => {
    return currentLanguage === "en" ? 'w-[87%] md:w-[70%] m-auto flex flex-col gap-5 list-none mt-8 mb-10 slidebar-box-text slidebar-box slidebar-box-animate-info' :
      'w-[70%] md:w-[60%] m-auto flex flex-col gap-5 list-none mt-6 mb-10 slidebar-box-text slidebar-box slidebar-box-animate-info'
  }

  return (
    <>
      <div className="font-sans">
        <main>
          <section id="hero" className="bg-gray-100 relative">
            {/* <ImageCarousel images={renderImages} slideCount={1} isNavigateSwap={true} setSlideIndex={setSlideIndex}>
                  {landingCarousel.map((carousel, index) => (

                    <div key={index} className='pt-8'>
                      <div
                        style={{ backgroundColor: carousel.backgroundColor, height: '105vh', paddingTop: '10px' }}
                      >
                        <div>
                          <img src="/images/logo-black.png" alt="" style={{ height: '45px' }} className={`my-8 m-auto`} />
                          {carousel.header &&
                            <div className="px-8">
                              <h1
                                className={`slidebar-box-h1	slidebar-box ${slideCount === index ? 'slidebar-box-animate' : ''}`}
                              >{carousel.header}</h1>

                              <p
                                className={`my-5 slidebar-box-text slidebar-box`}
                              >{carousel.description}</p>
                            </div>
                          }
                          <p className={`my-8 slidebar-box-h1 text-center slidebar-box`}
                          >{carousel.title}</p>

                          <img
                            className={`slidebar-box`}
                            src={carousel.image} style={{ height: '250px', width: '100%', objectFit: 'fill' }} />


                          <a href={carousel.link}>
                            <div
                              className={`mt-5 btn-gray ${carousel.btnName === 'DISCOVER MORE' ? 'w-1/2 md:w-2/5 ' : 'w-full md:w-4/5'} m-auto slidebar-box`}
                            >{carousel.btnName}</div>
                          </a >
                        </div>
                      </div>
                    </div>
                  ))}
                </ImageCarousel> */}

            <div className='relative carousel-full-screen'>
              {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-75">
                  <div className="w-12 h-12 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
                </div>
              )}
              <video onLoadedData={handleLoadedData} autoPlay={true} playsInline={true} muted={true} controls={false} loop={true} id="backgroundVideo">
                <source src={`${process.env.REACT_APP_BASE_URL}/theme/dashboard/latest-background.mp4`} type="video/mp4" />
              </video>
              <div className='scroll' onClick={() => scrollToNextSection('next')}>
                <img src="/icons/down-arrow.svg" alt="Down Arrow" height={40} className="arrow-down" />
              </div>

              <div className='slidebar-overlay slidebar-box-animate'>
                <div className="slidebar-wrapper relative">
                  <div>
                    <img
                      srcSet="/images/logo-black-small.png 50w, /images/logo-black.png 200w"
                      sizes="(max-width: 600px) 50px, (max-width: 1200px) 200px"
                      src="/images/logo-black.png"
                      loading="lazy" alt="Logo"
                      className={`h-[50px] md:h-[65px] mb-14 m-auto mt-16 md:mt-8`} />
                    <div className="px-4 md:px-8">
                      {/* <h1 className={` slidebar-box-h1	slidebar-box slidebar-box-animate-header uppercase border-b border-[#a89e8a] pb-4`}>
                        {t('sidebar.header')}
                      </h1> */}

                      <h1 className="slidebar-box-h1	slidebar-box slidebar-box-animate-header uppercase text-center">
                        {t('sidebar.header')}
                      </h1>

                      <h2 className="slidebar-box-h1 slidebar-box-h2 slidebar-box slidebar-box-animate-header uppercase border-b border-black pb-8 text-center">
                        {t('sidebar.subHeader')}
                      </h2>

                      <ul className={`${widthClass()}`}>
                        <li className="relative">
                          <span className="absolute text-[0.5rem] left-[-6%] top-1/2 transform -translate-y-1/2 text-black">
                            &#x25C6;
                          </span>
                          <span>{t('sidebar.list1')}</span>
                        </li>
                        <li className="relative">
                          <span className="absolute  text-[0.5rem] left-[-6%] top-1/2 transform -translate-y-1/2 text-black">
                            &#x25C6;
                          </span>
                          {t('sidebar.list2')}
                        </li>
                        <li className="relative ">
                          <span className="absolute  text-[8px] left-[-6%] top-1/2 transform -translate-y-1/2 text-black">
                            &#x25C6;
                          </span>
                          {t('sidebar.list3')}
                        </li>
                      </ul>
                    </div>

                    <img
                      className={`slidebar-box hidden md:block slidebar-box-animate-media`}
                      alt="Landing"
                      src='/images/landing.gif' style={{ height: '250px', width: '100%', objectFit: 'fill' }} />

                    <video autoPlay={true} playsInline={true} muted={true} controls={false} loop={true} className="block md:hidden slidebar-box-animate-media" style={{ minHeight: "auto", height: '40vh', margin: 'auto' }}>
                      <source src={`${process.env.REACT_APP_BASE_URL}/theme/dashboard/latest-background.mp4`} type="video/mp4" />
                    </video>


                    {/* <Link to="/offer" reloadDocument className="slidebar-enquire-btn slidebar-box-animate-media">
                      <div
                        className={`mt-16 btn-gray w-1/2 md:w-2/5 m-auto slidebar-box rounded-bl-xl rounded-tr-xl`}
                      >ENQUIRE NOW</div>
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="fixed bottom-2 left-2 w-20 h-20 bg-gray-1 rounded-full flex items-center justify-center  font-bold shadow-lg text-center z-[999]">
              <Link to="/offer" reloadDocument aria-label="offer">
                <div className="drop-shadow-lg relative">
                  <img
                    srcSet="/icons/offer-small.png 50w, /icons/offer.png 200w"
                    sizes="(max-width: 600px) 50px, (max-width: 1200px) 200px"
                    src="/icons/offer-small.png"
                    alt="offer"
                    className="p-1 opacity-0 animate-[imageSwap_4s_infinite] absolute top-[-12px]" />

                  <img
                    src="/icons/render-offer.png"
                    alt="offer"
                    className=" object-cover opacity-0 animate-[imageSwapReverse_4s_infinite]" />
                </div>
              </Link>
            </div>
          </section>

          {/* <section id="hero" className="bg-gray-100">
            <div className='relative'>
              <video autoPlay={true} muted={true} controls={false} loop={true} style={{ height: '100%', width: '100%' }} id="backgroundVideo">
                <source src="https://test-metaluxe-landing.b-cdn.net/theme/dashboard/latest-background.mp4" type="video/mp4" />
              </video>
              <div className='intro'>
                <p className='intro-title'>
                  3D VISUALISERS OF EXCELLENCE<br />
                  NOW ACCEPTING ORDERS FOR FEBRUARY 2025 DELIVERY <br /><br />
                  ENTICING INCENTIVES  FOR ORDERS PLACED  <br />
                  IN 2024
                </p>

                <div className='flex-row-center mt-36 mb-6 cursor-pointer' onClick={() => setIsModalOpen(true)}>
                  <img src="/icons/playbtn.svg" className='mr-2 playbtn' alt="" />
                  <p className='border-white border-b-2 intro_title'>WATCH JAMIE DURIE PRESENT METALUXE</p>
                </div>
              </div>
              <div className='scroll' onClick={scrollToNextSection}>
                <p className='text-white'>SCROLL</p>
                <svg data-name="Layer 1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 9.81 30" className='arrow'>
                  <path fill="none" stroke="#fff" d="M4.91 0v24.8"></path><path fill="#fff" d="M4.91 30l4.9-5.27H0L4.91 30z"></path>
                </svg>
              </div>
            </div>
          </section> */}

          {/* <div id='banner' ref={nextSectionRef}>
            <div className='animate-slide-in-left'>
              <div className="banner_slide flex-center">
                <p className='text-p'>
                  <Link to="/offer" reloadDocument>ENQUIRE NOW</Link>
                </p>
              </div>
            </div>no confusion.
          </div> */}

          <section className='mt-28 mb:16 md:mt-16  pt-0 md:pt-16' ref={sectionRef.next}>
            <div className='fade-in-section relative text-center'>
              <h1 className='header text-2xl md:text-4xl text-black tracking-widest text-center'>
                {t('section1.header')}
              </h1>
              <p className="text-p uppercase pt-8">{t('section1.subHeader')}</p>
              <div className='section-entry'>
                <p className="text-p-s pt-6 pb-0">
                  {t('section1.description')}
                </p>
              </div>

              {/* <Link to="/offer" reloadDocument className="btn rounded-bl-xl rounded-tr-xl mt-16">
                ENQUIRE NOW
              </Link> */}
            </div>
          </section>


          <section className='mt-4 mb-4 md:mb-16 '>
            <div className='shell'>
              <div className='fade-in-section'>
                <div className={`grid ${currentLanguage === "en" ? 'grid-cols-1  md:grid-cols-3 lg:grid-cols-5' : 'grid-cols-1   md:grid-cols-3 lg:grid-cols-6'} gap-2`}>
                  {currentLanguage === "zh" &&
                    <div className='flex flex-col border-top pt-5'>
                      {discoverMore.jser &&
                        <div className="md-4 md:mb-8">
                          <Jser setIsModalOpen={setIsModalJenOpen} show={true} t={t} />
                        </div>
                      }
                      {!discoverMore.jser &&
                        <div className='flex flex-col h-full mb-4 md:mb-0'>
                          <div className="flex-row-between">
                            <p className='title uppercase'>{t('section0.header')}</p>
                          </div>
                          <img src="/images/jsher.png" className='my-5 h-full object-cover' alt={t('section0.header')} />
                          <div onClick={() => showDiscoverMore('jser')} className='btn btn-jamie rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none block md:hidden'>{t('discoverMore')}</div>
                          <a href="#jennifer" className='btn btn-jamie rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none hidden md:block uppercase'>{t('discoverMore')}</a>
                        </div>
                      }
                    </div>
                  }
                  <div className='flex flex-col border-top pt-5'>
                    {discoverMore.vision &&
                      <div className="mb-4 md:mb-8">
                        <VisionToLife setIsModalOpen={setIsModalOpen} show={true} t={t} />
                      </div>
                    }
                    {!discoverMore.vision &&
                      <div className='flex flex-col h-full mb-4 md:mb-0'>
                        <div className="flex-row-between relative w-full z-[999]">

                          <Tooltip text={t('sectionLabel.tooltip')}>
                            <p className='title uppercase'>{t('sectionLabel.jamie')}</p>
                          </Tooltip>

                        </div>
                        <img src="/images/jamie.png" className='my-5 h-full object-cover' alt={t('sectionLabel.jamie')} />
                        <div onClick={() => showDiscoverMore('vision')} className='btn btn-jamie rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none block md:hidden'>{t('discoverMore')}</div>
                        <a href="#vision-to-life" className={`btn btn-jamie ${currentLanguage === "zh" ? 'md:rounded-none rounded-bl-3xl rounded-tr-3xl' : 'rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none'} hidden md:block uppercase`}>{t('discoverMore')}</a>
                      </div>
                    }
                  </div>
                  <div className='flex flex-col border-top pt-5'>

                    {discoverMore.animation &&
                      <div className="mb-4 md:mb-8">
                        <Animation setIsVideoOpen={setIsVideoOpen} show={true} t={t} />
                      </div>
                    }

                    {!discoverMore.animation &&
                      <div className='flex flex-col mb-4 md:mb-0'>
                        <div className='flex-row-between'>
                          <p className='title uppercase'>{t('sectionLabel.animation')}</p>
                        </div>
                        <img src="/images/cinematics.png" className='my-5 h-full object-cover' alt={t('sectionLabel.animation')} />
                        <div onClick={() => showDiscoverMore('animation')} className='btn btn-jamie rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none block md:hidden'>{t('discoverMore')}</div>
                        <a href="#cinematic" className='btn md:rounded-none rounded-bl-3xl rounded-tr-3xl hidden md:block uppercase'>{t('discoverMore')}</a>
                      </div>
                    }
                  </div>
                  <div className='flex flex-col border-top pt-5'>

                    {discoverMore.virtualTour &&
                      <div className="mb-4 md:mb-8">
                        <VirtualTour setIsTourOpen={setIsTourOpen} show={true} t={t} />
                      </div>
                    }

                    {!discoverMore.virtualTour &&
                      <div className='flex flex-col mb-4 md:mb-0 h-full'>
                        <div className="flex-row-between">
                          <p className='title uppercase'>{t('sectionLabel.virtualTour')}</p>

                        </div>
                        <img src="/images/360-tour.png" className='my-5 h-full object-cover' alt={t('sectionLabel.virtualTour')} />
                        <div onClick={() => showDiscoverMore('virtualTour')} className='btn btn-jamie rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none block md:hidden'>{t('discoverMore')}</div>
                        <a href="#360-tour" className='btn md:rounded-none rounded-bl-3xl rounded-tr-3xl hidden md:block uppercase'>{t('discoverMore')}</a>
                      </div>
                    }

                  </div>
                  <div className='flex flex-col border-top pt-5'>
                    {discoverMore.renders &&
                      <div className="mb-4 md:mb-8">
                        <Renders show={true} t={t} setIsRenderOpen={setIsRenderOpen} />
                      </div>
                    }
                    {!discoverMore.renders &&
                      <div className='flex flex-col mb-4 md:mb-0 h-full'>
                        <div className="flex-row-between">
                          <p className='title uppercase'>{t('sectionLabel.renders')}</p>
                        </div>
                        <img src="/images/render.png" className='my-5 h-full object-cover' alt={t('sectionLabel.renders')} />
                        <div onClick={() => showDiscoverMore('renders')} className='btn btn-jamie rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none block md:hidden'>{t('discoverMore')}</div>
                        <a href="#renders" className='btn md:rounded-none rounded-bl-3xl rounded-tr-3xl hidden md:block uppercase'>{t('discoverMore')}</a>
                      </div>
                    }
                  </div>
                  <div className='flex flex-col border-top pt-5'>

                    {discoverMore.app &&
                      <div className="mb-4 md:mb-8">
                        <YouOwnApp show={true} t={t} />
                      </div>
                    }
                    {!discoverMore.app &&
                      <div className='flex flex-col h-full'>
                        <div className="flex-row-between">
                          <p className='title uppercase'>{t('sectionLabel.yourOwnApp')}</p>
                        </div>
                        <img src="/images/custom-app.png" className='my-5 h-full object-cover' alt={t('sectionLabel.yourOwnApp')} />
                        <div onClick={() => showDiscoverMore('app')} className='btn btn-jamie rounded-bl-3xl rounded-tr-3xl md:rounded-bl-3xl md:rounded-tr-none block md:hidden'>{t('discoverMore')}</div>
                        <a href="#custom-apps" className='btn rounded-bl-3xl rounded-tr-3xl md:rounded-tr-3xl md:rounded-bl-none hidden md:block uppercase'>{t('discoverMore')}</a>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-0 mb:pb-16" />
          </section>
          {currentLanguage === "zh" &&
            <section className='my-16 hidden md:block'>
              <Jser setIsModalOpen={setIsModalJenOpen} show={true} t={t} scrollToNextSection={scrollToNextSection} />
              <div className="pb-16" />
            </section>
          }

          <section className='my-16 hidden md:block' ref={sectionRef.vision}>
            <VisionToLife setIsModalOpen={setIsModalOpen} t={t} scrollToNextSection={scrollToNextSection} />
            <div className="pb-8" />

          </section>


          <section className='my-16 hidden md:block' ref={sectionRef.animation}>
            <Animation setIsVideoOpen={setIsVideoOpen} t={t} scrollToNextSection={scrollToNextSection} />
            <div className="pb-8" />
          </section>


          <section className='hidden md:block' ref={sectionRef.virtualTour}>
            <VirtualTour setIsTourOpen={setIsTourOpen} t={t} scrollToNextSection={scrollToNextSection} />
            <div className="pb-8" />

          </section>


          <section className='mt-16 mb-16 hidden md:block' ref={sectionRef.render}>
            <Renders t={t} scrollToNextSection={scrollToNextSection} setIsRenderOpen={setIsRenderOpen} />
            <div className="pb-8" />

          </section>

          <section className='my-16 hidden md:block' id="custom-apps" ref={sectionRef.ownApp}>
            <YouOwnApp t={t} scrollToNextSection={scrollToNextSection} />
            <div className="pb-8" />
          </section>

          <section className='my-16 md:pb-8' id="offers">
            <div className='fade-in-section relative text-center'>
              <h1 className='header text-2xl md:text-4xl text-black tracking-widest text-center'>{t('section8.header')}</h1>
              <div className="relative my-8">
                <img
                  srcSet="/icons/offer-black-small.png 50w, /icons/offer-black.png 200w"
                  sizes="(max-width: 600px) 50px, (max-width: 1200px) 200px"
                  src="/icons/offer-black.png"
                  loading="lazy" alt="offer"
                  className="h-[80px] m-auto opacity-0 animate-[imageSwap_4s_infinite] absolute top-[-10px] left-[50%] transform -translate-x-1/2" />
                <img src="/icons/render-offer-black.png" alt="offer" className="h-[60px] m-auto  opacity-0 animate-[imageSwapReverse_4s_infinite]" />
              </div>
              <div className='section-entry'>
                <p className="text-p-s px-8 pb-0">
                  {t('section8.description')}
                </p>
              </div>

              {/* <Link to="/offer" reloadDocument className="btn rounded-bl-xl rounded-tr-xl">
                ENQUIRE NOW
              </Link> */}
            </div>
          </section>

          {/* <section className='my-16' id="offers">
            <div className='fade-in-section relative text-center'>
              <h1 className='header text-2xl md:text-4xl text-black tracking-widest text-center'>Let’s Bring Your Vision to Life</h1>
              <div className='section-entry'>
                <p className="text-p-s px-8 pt-8 pb-4">
                  Partner with Metaluxe to create engaging, lifelike digital experiences that drive sales. Connect with our expert team to elevate your project
                </p>
              </div>

               <Link to="/offer" reloadDocument className="btn rounded-bl-xl rounded-tr-xl">
                ENQUIRE NOW
              </Link> 
            </div>
          </section> */}
          <Footer t={t} />
        </main>
      </div >
      <Modal isOpen={isModalJenOpen} setIsOpen={setIsModalJenOpen} >
        <video autoPlay={true} playsInline={true} muted={false} controls={true} loop={true} className="w-full watch-video">
          <source src={`${process.env.REACT_APP_BASE_URL}/theme/dashboard/jennifer.mp4`} type="video/mp4" />
        </video>
      </Modal>

      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <video autoPlay={true} playsInline={true} muted={false} controls={true} loop={true} className="w-full watch-video">
          <source src={`${process.env.REACT_APP_BASE_URL}/theme/dashboard/watch-video.mp4`} type="video/mp4" />
        </video>
      </Modal>

      <Modal isOpen={isVideoOpen} setIsOpen={setIsVideoOpen}>
        <video autoPlay={true} playsInline={true} muted={false} controls={true} loop={true} className="w-full watch-video">
          <source src={`${process.env.REACT_APP_BASE_URL}/theme/dashboard/showcase.mp4`} type="video/mp4" />
        </video>
      </Modal>

      <Modal isOpen={isTourOpen} setIsOpen={setIsTourOpen}>
        {isIframeLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-75">
            <div className="w-12 h-12 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
          </div>
        )}
        <iframe src="https://storage.net-fs.com/hosting/7566552/23/"
          onLoad={handleIframeLoad}

          title="2 Bed MRP North Aspect" width="100%" height="100%" allow="fullscreen"></iframe>
      </Modal>

      <Modal isOpen={isRenderOpen} setIsOpen={setIsRenderOpen}>
        <div className="h-full" id="renderModal">
          <ImageCarousel images={renderImages} slideCount={1} >
            {renderImages.map((image, index) => (
              <div key={index} >
                <img src={`/images/renders/${image}`} alt={`Slide ${image}`} className="w-full h-auto" />
              </div>
            ))}
          </ImageCarousel>
        </div>
      </Modal>
    </>
  );
};

export default Home;
