import React from 'react';

function TermsAndCondition
  () {
  return (
    <div className="container px-5 md:px-20 py-20">
      <h1 className="text-2xl md:text-3xl">Terms And Conditions</h1>
      <div className="flex flex-col gap-10 pt-4 ">
        <div className="flex flex-col gap-2">
          <h3 className="text-2xl">Introduction</h3>
          <p>The Metaluxe website, <a href="www.Metaluxe.com.au">www.Metaluxe.com.au</a>, is aportfolio website that showcases the developments built by Metaluxe. By usingthis website, you agree to be bound by the following terms and conditions ofuse.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-2xl">10% OFF + 5 FREE RENDERS ( Offer )</h3>
          <p>Applies only to purchases of no less than $10,000, is limited to 1 time per new customer. Render resolution is HD 2K and Renders are new angles within spaces that have already been built. Orders must be placed with deposits paid before Christmas 2024.
            .</p>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-2xl">Content</h3>
          <p>The content on this website, including text, graphics, logos,images, and other material, is the property of Metaluxe and is protected bycopyright and other intellectual property laws. You may not use, reproduce,modify, distribute, or display any content from this website without theexpress written permission of Metaluxe.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-2xl">Use of the Website</h3>
          <p>
            You may use this website for lawful purposes only. You may not usethis website for any illegal or unauthorized purposes, including but notlimited to, the posting of malicious or offensive content, the transmission ofspam or other unsolicited messages, or the distribution of viruses or otherharmful software.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-2xl">Links to Third-Party Websites
          </h3>
          <p>
            This website may contain links to third-party websites. Theselinks are provided for your convenience only, and Metaluxe is not responsiblefor the content or privacy practices of these websites. You should carefullyreview the terms of use and privacy policies of any third-party websites youvisit.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-2xl">Warranties and Disclaimers

          </h3>
          <p>
            Metaluxe makes no representations or warranties of any kind,express or implied, about the completeness, accuracy, reliability, suitability,or availability with respect to the website or the information, products,services, or related graphics contained on the website for any purpose. Anyreliance you place on such information is therefore strictly at your own risk.
            <br />
            Metaluxe will not be liable for any errors, omissions, or delaysin the content of this website, or for any actions taken in reliance on theinformation contained on this website.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-2xl">Contact Us</h3>
          <p>
            If you have any questions or concerns about these terms of use,please contact us through the "Contact Us" form on our website.
          </p>
        </div>

        <p>Last Revised: October 23, 2024</p>
      </div>
    </div>
  );
}

export default TermsAndCondition;
