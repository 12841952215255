import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Header = ({ isHeaderColorActive, t, currentLanguage, handleChangeLanguage }) => {
  const location = useLocation()

  const isHeaderBlack = () => {
    return (location.pathname !== '/') && isHeaderColorActive
  }

  return (
    <header className={`flex justify-between items-center top-0 py-4 px-2 md:px-10 lg:px-10  text-white fixed w-full z-10`}>
      <div>
        {(location.pathname === "/" || location.pathname === "/offer") &&
          <div onClick={handleChangeLanguage} type="button" className="w-full text-gray-900 font-medium text-right md:text-center pl-2 cursor-pointer py-4 md:py-2 block md:hidden">
            {currentLanguage === 'en' ? '中文' : 'Change to EN'}
          </div>
        }
      </div>
      {isHeaderBlack() && <Link to="/" >
        <img src={`/images/${isHeaderColorActive ? 'logo-black' : 'logo'}.png`}
          className={`${currentLanguage === 'en' ? 'pl-0 md:pl-36' : 'pl-0 md:pl-36'} h-8 md:h-11 fixed left-[40%] top-[25px] md:top-0 md:relative`}
          alt="" />
      </Link>}
      <div className="text-center flex w-auto md:w-[350px] items-center">
        {(location.pathname === "/" || location.pathname === "/offer") &&
          <div onClick={handleChangeLanguage} type="button" className="w-full text-gray-900 font-medium text-right pr-4 cursor-pointer py-2 hidden md:block">
            {currentLanguage === 'en' ? '中文' : 'Change to EN'}
          </div>
        }
        <Link to="/offer" className="w-full">
          <button className="text-p-m shadow-custom transition-transform transform hover:-translate-y-0.5 rounded-bl-xl rounded-tr-xl bg-[#1a2a58]  text-[#FFFFF0] border border-[#1a2a58] font-bold py-2 px-2 md:px-4 uppercase w-full text-center">
            {t('enquireNow')}
          </button>
        </Link>

      </div>
    </header>
  );
};

export default Header;
